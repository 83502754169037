import React, { CSSProperties, FC, useMemo } from 'react';
import Styles from "../../styles/SelectButton.module.scss";
import clsx from "clsx"
import { Tag } from '../../types/models/Tag';

type Props<T> = {
    label: string | JSX.Element,
    name: string,
    value: T,
    isSelected: boolean,
    onChange: ( name: string, value: T, bool: boolean ) => void,
    style?: CSSProperties
    labelStyle?: CSSProperties
}

const SelectButton = <T extends string | number> ( {
    label,
    name,
    value,
    isSelected,
    onChange,
    style,
    labelStyle
}: Props<T> ) =>
    useMemo( () =>
        <div className={ Styles.container } style={ style }>
            <input
                className={ Styles.select_button_input }
                type="checkbox"
                name={ name }
                value={ value }
                id={ name + value }
                checked={ isSelected }
                onChange={ () => onChange( name, value, !isSelected ) } />
            <label
                className={ clsx(
                    Styles.select_button_label,
                    Styles.select_button_label,
                    name === Tag.MYTAG &&
                    Styles.accent_color_select_button_label ) }
                style={ labelStyle }
                htmlFor={ name + value }>
                { label }
            </label>
        </div>, [ isSelected, label, onChange ] )

export default SelectButton
