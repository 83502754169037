import { useState, useEffect } from 'react'
import { LocalSettingsKey, LocalSettingsKeyType, SettingsType } from '../types/app'
import { useIsLoggedIn } from './useIsLoggedIn';

type Props = {
    deps?: any[]
}

export const useLocalSettings = ( { deps = [] }: Props ) => {

    const [ settings, setSettings ] = useState<SettingsType>( {} as SettingsType );
    const isLoggedIn = useIsLoggedIn()

    useEffect( () => {
        isLoggedIn && loadLocalSettings()
    }, [ ...deps, isLoggedIn ] )

    const loadLocalSettings = () => {
        const loadedSettings = {} as SettingsType
        const localSettingsKeys = Object.keys( LocalSettingsKey ) as LocalSettingsKeyType[]
        localSettingsKeys.forEach( async key => {
            const value = localStorage.getItem( key )
            loadedSettings[ key ] = value ? JSON.parse( value ) : false
        } )
        setSettings( loadedSettings )
    }
    return settings
}
