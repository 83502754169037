import React, { FC } from "react"
import Styles from "../../styles/GeneralButton.module.scss";
import SimpleActivityIndicator from "./SimpleActivityIndicator";
import clsx from "clsx"

type GeneralButton = {
    title: string | JSX.Element,
    style?: React.CSSProperties,
    onClick?: () => void,
    className?: string,
    loading?: boolean,
    textStyle?: React.CSSProperties,
    isDisabled?: boolean
    disabledOnClick?: () => void
    transparent?: boolean
    outline?:boolean
}

const GeneralButton: FC<GeneralButton> = ( {
    title,
    style,
    onClick,
    className,
    loading = false,
    textStyle,
    isDisabled,
    disabledOnClick,
    transparent,
    outline
} ) =>
    <button
        className={ clsx( 
            Styles.container, 
            className, 
            "general_button", 
            isDisabled && Styles.disabled,
            outline && Styles.outline ) }
        style={ { ...style, ...( transparent ? { background: "rgba(0,0,0,0)" } : {} ) } }
        onClick={ () => isDisabled ?
            disabledOnClick && disabledOnClick() :
            onClick && onClick() }>
        <span style={ textStyle ? textStyle : {} }>
            { title }
        </span>
        { loading &&
            <SimpleActivityIndicator style={ { marginLeft: 8 } } /> }
    </button>

export default GeneralButton
