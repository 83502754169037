import React, { CSSProperties, FC } from 'react';
import Styles from "../../styles/Tag.module.scss";
import { TagType } from '../../types/models/Tag';

type Props = {
    tag: TagType,
    style: CSSProperties,
    onClick?: ( e: React.MouseEvent<HTMLSpanElement, MouseEvent>, tag: TagType ) => void
}

const Tag: FC<Props> = ( {
    tag,
    style,
    onClick = () => { }
} ) =>
    <span
        className={ `${ Styles.container } ${ Styles[ tag.type ] }` }
        style={ style }
        onClick={ e => onClick && onClick( e, tag ) }>
        <span>{ tag.name }</span>
    </span>

export default Tag
