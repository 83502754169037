export const Tag = {
    TAG: "tag",
    MYTAG: "mytag"
} as const

export type TagTypeType = typeof Tag[ keyof typeof Tag ]

export type TagType = {
    pk: string
    name: string
    type: TagTypeType
}
