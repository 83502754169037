export const LocalSettingsKey = {
    isLikeActive: "isLikeActive",
    isMyTagActive: "isMyTagActive",
    commentDateTimeVisible: "commentDateTimeVisible",
    serialPost: "serialPost",
    showUserNameOnTimeline: "showUserNameOnTimeline"
} as const

export type LocalSettingsKeyType = typeof LocalSettingsKey[ keyof typeof LocalSettingsKey ]
export type SettingsType = {
    [ key in LocalSettingsKeyType ]: boolean
}
