import React, { CSSProperties, FC } from "react"

type Props = {
    style: CSSProperties
}

const SimpleActivityIndicator: FC<Props> = ( {
    style
} ) =>
    <div className="sk-circle-fade" style={ style }>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
        <div className="sk-circle-fade-dot"></div>
    </div>

export default SimpleActivityIndicator
