import React, { useState, useEffect, useContext } from "react"
import Styles from "../styles/tag_management.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import GeneralButton from "../components/atoms/GeneralButton";
import SelectButton from "../components/atoms/SelectButton";
import Tag from "../components/atoms/Tag";
import { Tag as TagAlias, TagType, TagTypeType } from "../types/models/Tag"
import { ToastContext } from '../context/ToastContext';
import { BACK_BUTTON_STR } from "../constant/const";
import { VectorType } from "../types/TouchOrMouseEvent";
import client from "../apis/client";
import { useLocalSettings } from "../hooks/useLocalSettings";

const MyPage = () => {

    const headerOption = {
        headerTitle: "タグ管理",
        leftIcon: BACK_BUTTON_STR,
    }

    const [ newTagName, setNewTagName ] = useState( "" )
    const [ newTagType, setNewTagType ] = useState<TagTypeType>( TagAlias.MYTAG )
    const [ selectedTag, setSelectedTag ] = useState<TagType | null>( null )
    const [ updateTagName, setUpdateTagName ] = useState( "" )

    const [ tagCreating, setTagCreating ] = useState( false )
    const [ tagUpdating, setTagUpdating ] = useState( false )
    const [ tagDeleting, setTagDeleting ] = useState( false )

    const [ tagList, setTagList ] = useState<TagType[]>( [] )
    const [ myTagList, setMyTagList ] = useState<TagType[]>( [] )
    const [ userTeam, setUserTeam ] = useState( null )
    const [ tagUpdateBoxCoordinates, setTagUpdateBoxCoordinates ] = useState<VectorType>( { X: 0, Y: 0 } )
    const [ tagUpdateBoxIsLocatedLow, setTagUpdateBoxIsLocatedLow ] = useState( false )

    const { addToast } = useContext( ToastContext );
    const localSettings = useLocalSettings( {} )
    const showMyTag = ( !userTeam || localSettings.isMyTagActive )

    useEffect( () => {
        getTags()
    }, [] )

    useEffect( () => {
        !showMyTag && setNewTagType( TagAlias.TAG )
    }, [ showMyTag ] )

    const getTags = async () => {
        await client.get( `/api/tag/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } )
            .then( response => {
                const data = response.data
                setTagList( data.tag_list )
                setMyTagList( data.mytag_list )
                setUserTeam( data.team )
            } )
    }

    const onChangeCreateTagType = ( name: string, value: string, isSelected: boolean ) => {
        if ( name === TagAlias.TAG ) {
            if ( !userTeam ) {
                addToast( { text: "チームに所属していないとチームタグは使用できません。", type: "warn" } )
                // alert( "チームに所属していないとチームタグは使用できません。" )
                return
            }
            setNewTagType( TagAlias.TAG )
        }
        else {
            setNewTagType( TagAlias.MYTAG )
        }
    }

    const createNewTag = async () => {
        if ( !newTagName ) return
        if ( tagCreating ) return
        const data = new FormData()
        data.append( "name", newTagName )
        data.append( "public_or_private", newTagType === TagAlias.MYTAG ? TagAlias.MYTAG : "public_tag" )
        await client.post( `/api/tag/`, data ).
            then( ( response ) => {
                setTagCreating( false )
                setNewTagName( "" )
                addToast( { text: "作成しました", type: "success" } )
                // アプリ側の名前も変える
                getTags()
            } )
            .catch( error => {
                setTagCreating( false )
                console.log( "error.response.data", error.response.data );
            } )
    }

    const updateTag = async () => {
        if ( !updateTagName ) return
        if ( !selectedTag ) return
        if ( tagUpdating ) return
        const data = new FormData()
        data.append( "name", updateTagName )
        setTagUpdating( true )
        await client.patch( `/api/tag/${ selectedTag.pk }/`, data ).
            then( ( response ) => {
                setSelectedTag( null )
                setUpdateTagName( "" )
                addToast( { text: "更新しました", type: "success" } )
                // アプリ側の名前も変える
                getTags()
            } )
            .catch( error => {
                setTagUpdating( false )
                console.log( "error.response.data", error.response.data );
            } )
        setTagUpdating( false )

    }

    const deleteTag = async () => {
        if ( tagDeleting ) return
        if ( !selectedTag ) return
        if ( !window.confirm( "本当にこのタグを削除しますか？" ) ) {
            return
        }
        setTagDeleting( true )
        await client.delete( `/api/tag/${ selectedTag.pk }/` )
            .then( () => {
                setSelectedTag( null )
                setUpdateTagName( "" )
                addToast( { text: "削除しました", type: "success" } )
                //アプリ側からも消す
                getTags()
            } )
            .catch( error => {
                console.log( "error", error )
                console.log( "error.response.data", error.response.data );
            } )
        setTagDeleting( false )
    }

    const renderTagList = () =>
        tagList.map( tag =>
            <Tag
                key={ tag.pk }
                style={ { marginRight: 8 } }
                // tagType={"tag"} 
                tag={ tag }
                onClick={ ( event, tag ) => displayTagUpdateBox( event, tag ) } /> )

    const renderMyTagList = () =>
        myTagList.map( tag =>
            <Tag
                key={ tag.pk }
                style={ { marginRight: 8 } }
                tag={ tag }
                onClick={ ( event, tag ) => displayTagUpdateBox( event, tag ) } /> )

    const displayTagUpdateBox = ( event: React.MouseEvent<HTMLSpanElement, MouseEvent>, tag: TagType ) => {
        setSelectedTag( tag )
        setUpdateTagName( tag.name )
        let X
        if ( window.innerWidth <= 900 ) { //スマホ
            X = window.innerWidth / 2 > event.clientX ? event.clientX : event.clientX - window.innerWidth / 2
        }
        else {
            X = event.clientX
        }
        let Y = event.clientY
        window.innerHeight / 2 < event.clientY ? setTagUpdateBoxIsLocatedLow( true ) : setTagUpdateBoxIsLocatedLow( false )

        setTagUpdateBoxCoordinates( { X, Y } )
    }

    const renderTagUpdateBox = () =>
        <div className={ Styles.tag_update_box }
            style={ {
                position: "fixed",
                top: tagUpdateBoxCoordinates.Y,
                left: tagUpdateBoxCoordinates.X,
                transform: tagUpdateBoxIsLocatedLow ?
                    "translate3d(0,-100%,0)" : ""
            } }>
            <input
                type="text"
                value={ updateTagName }
                onChange={ ( e ) => setUpdateTagName( e.target.value ) } />
            <ul>
                <li>
                    <GeneralButton title="変更"
                        style={ { width: "100%" } }
                        loading={ tagUpdating }
                        onClick={ () => updateTag() } />
                </li>
                <li>
                    <GeneralButton title="削除"
                        style={ {
                            width: "100%",
                            background: Styles.accent_color,
                        } }

                        className={ Styles.delete_button }
                        loading={ tagDeleting }
                        onClick={ () => deleteTag() } />
                </li>
                <li>
                    <GeneralButton title="キャンセル" style={ { width: "100%" } }
                        onClick={ () => setSelectedTag( null ) } />
                </li>
            </ul>
        </div>



    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="Home" />
                <div className={ Styles.container }>
                    <div className={ Styles.new_tag_create }>
                        <div className={ Styles.create_tag_type_wrapper }>
                            <SelectButton
                                style={ { width: "100%", textAlign: "center", marginBottom: showMyTag ? 8 : -8 } }
                                label="チームタグ"
                                name={ TagAlias.TAG }
                                value={ TagAlias.TAG }
                                isSelected={ newTagType === TagAlias.TAG }
                                onChange={ onChangeCreateTagType } />
                            { showMyTag &&
                                <SelectButton
                                    label="Myタグ"
                                    name={ TagAlias.MYTAG }
                                    value={ TagAlias.MYTAG }
                                    labelStyle={ { marginBottom: 0 } }
                                    isSelected={ newTagType === TagAlias.MYTAG }
                                    onChange={ onChangeCreateTagType } /> }
                        </div>
                        <input
                            type="text"
                            placeholder="新しいタグの名前"
                            className={ Styles.new_tag_name_input }
                            value={ newTagName }
                            onChange={ ( e ) => setNewTagName( e.target.value ) } />
                        <GeneralButton
                            title="作成"
                            style={ { marginLeft: 8, flexShrink: 0 } }
                            loading={ tagCreating }
                            onClick={ () => createNewTag() } />
                    </div>
                    { !showMyTag &&
                        <p style={ {
                            width: "100%",
                            paddingLeft: 12,
                            marginBottom: 12,
                            color: "rgba(0,0,0,0.54)",
                            whiteSpace: "pre-line",
                            lineHeight: 1.4,
                            textAlign: "left"
                        } }>
                            { "自分にのみ見えるタグ、Myタグも使えます。\n気に入った投稿につけておくと、あとで検索がしやすくなります。\n使いたい場合は、設定からご変更ください" }
                        </p> }
                    <h2 className={ Styles.title_tag_list }>タグ一覧</h2>
                    <div className={ Styles.tag_list_container }>
                        { selectedTag && renderTagUpdateBox() }
                        <div className={ Styles.tag_list }>
                            { renderTagList() }
                        </div>
                        { localSettings.isMyTagActive &&
                            <div className={ Styles.tag_list }>
                                { renderMyTagList() }
                            </div> }
                    </div>
                </div>
            </>
        </Layout>
    )
}

export default MyPage
